<template>
  <div id="breadcrumb">
    <span class="home">
      <router-link :to="{ name: 'home' }">{{ $t('home.home') }}</router-link>
    </span>
    <template v-if="partner">
      <span class="sep"> &raquo; </span>
      <span class="ville">
        <router-link :to="{ name: 'partner' }">{{ partner.name }}</router-link>
      </span>
    </template>
    <template v-if="favourite">
      <span class="sep"> &raquo; </span>
      <span class="favourite">
        <router-link :to="{ name: 'favourite' }">{{ $t('home.favourite') }}</router-link>
      </span>
    </template>
    <template v-else-if="shop">
      <span class="sep"> &raquo; </span>
      <span class="commercant">
        <router-link :to="{ name: 'shop' }">{{ shop.name }}</router-link>
      </span>
    </template>
    <template v-if="category">
      <span class="sep"> &raquo; </span>
      <span class="commercant">
        <router-link :to="{ name: 'shop', query: { category: categoryId }, hash: '#products' }">{{ category.title[$l] || category.title.fr }}</router-link>
      </span>
    </template>
    <template v-if="product">
      <span class="sep"> &raquo; </span>
      <span class="produit">
        <router-link :to="{ name: 'product' }">{{ product }}</router-link>
      </span>
    </template>
  </div>
</template>

<script>
import lodash from 'lodash'

export default {
  name: 'Breadcrumb',
  props: {
    product: { type: String },
    favourite: { type: Boolean },
    categoryId: { type: [String, Number] }
  },
  data: () => ({
    categories: []
  }),
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    shop() {
      return this.$store.getters['shop/current']
    },
    category() {
      return this.categories.find(c => c.id === this.categoryId)
    }
  },
  watch: {
    categoryId: 'loadCategories'
  },
  methods: {
    loadCategories() {
      return this.$store.dispatch('shop/categories', { id: this.shop.id })
        .then(categories => {
          const flatten = cs => cs.reduce((acc, c) => {
            acc.push(lodash.omit(c, 'nodes'))
            return acc.concat(flatten(c.nodes || []))
          }, [])
          this.categories = flatten(categories)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#breadcrumb {
  text-align: left;

  span a {
    font-weight: 500;
    font-size: 17px;
    text-decoration: none;
    color: var(--dark-grey-color) !important;

    &:hover {
     text-decoration: underline;
    }
  }

  span:last-child {
    color: var(--red-color) !important;

    a:hover {
      text-decoration: none !important;
    }
  }
}

.sep {
  margin: 0 5px;
}
</style>
